import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w8pic_1.png';
import w1pic_2 from './img/w8pic_2.png';
import w1pic_3 from './img/w8pic_3.png';
import w1pic_4 from './img/w8pic_4.png';
import w1pic_5 from './img/w8pic_5.png';
import w1pic_6 from './img/w1pic_6.jpg';
import w1pic_7 from './img/w1pic_7.jpg';

class PortfolioItemPool1 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	render() {
		const headline = 'Nest Control'
		const sub_headline = 'Production Processes Control Tool'
		const main_pic = { mainpic }
		var preface = '\bNest Control\b is a system for Technical Product Managers to plan and control production processes more efficiently when developing hardware products.'
		const role = 'Role: User Research, Interaction Design, Visual Design, Product Icon Design, Usability Testing.'
		const time = 'Date: Nov 2019 ';
		const client = 'Client: Google';
		const nda = 'Due to NDA, I’m not allowed to reveal complete design processes and deliverables. All the data is redacted and would be used for portfolio building only.'
		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [
			{
				title: 'Overview',
				desc: "\bManufacturing Control\b is a system for Google TPMs to \bcontrol production processes more efficiently\b when monitoring production lines \bremotely\b. Applying real time information and integrating production applications, the system not only enables TPMs to vet production processes remotely, but also allows the company to gain control of the manufacturing data."

			},

			{
				title: 'User Problem',
				desc:'All TPMs have to travel to the local factory to get the first hand data and to monitor the production line. It is costly and time consuming. What users need is a more \bcost effective & time efficient\b system to implement the production line and to monitor the whole process.',

			},

			{
				title: 'Challenges',
				desc: '\bMost of the users\b are used to the current workflows and quite \bfamiliar with the old systems\b even though there are room for improvements. Therefore, the challenge would be: ',
				pic: [{ w1pic_2 }]
			},

			{
				title: 'Exploring Potential User Needs',
				desc: '\bContextual Inquiry\b was conducted across stakeholders to \bdiscover underlying user needs\b. We observed that people spend a lot of time searching information within multiple platforms and sometimes the data might be incorrect or out-of-date.',

			},

			{
				title: 'Creating New User Value',
				subtitle: 'One platform for all',
				desc: 'To attract potential users to adopt our new system, we integrated all the information they needed in one platform to help users to accelerate working processes.',
				pic: [{ w1pic_3 }]
			},

			{
				subtitle: 'Competing Tasks in one place',
				desc: 'Besides, we combined multiple tasks into one single table for users to complete all tasks in one place.',
				pic: [{ w1pic_4 }]
			},

			{
				title: 'Keep Iterating based on User Feedback',
				desc: 'Both \bqualitative\b and \bquantitative\b feedback was collected during each iteration to examine design metrics.',
				pic: [{ w1pic_5 }]
			},

			{
				title: 'Takeaways',
				subtitle: 'The challenge of product adoption',
				desc: 'Once a habit is established, It’s difficult to change it. That is to say, the product we proposed should be valuable enough that users will be willing to adapt it.',

			},

			{
				subtitle: 'The importance of triangulating in user feedback',
				desc: 'Feedback could overlap with each other during different product design phases. It’s important to triangulate all the information to increase the credibility of the findings and to validate the product performance.'
			},


		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='pic'>
						<img src={mainpic} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='client'>
						<span>💼 {client}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='nda'>
						<span>{nda}</span>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool1;
