import React, { Component } from 'react';
import YouTube from 'react-youtube';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w9pic_1.png';
import w1pic_2 from './img/w11pic_1.png';
import w1pic_3 from './img/w11pic_2.gif';
import w1pic_4 from './img/w11pic_3.png';
import w1pic_5 from './img/w10pic_4.gif';
import w1pic_6 from './img/w10pic_5.png';
import w1pic_7 from './img/w1pic_7.jpg';

class PortfolioItemPool1 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	_onReady(event) {
		// access to player in all event handlers via event.target
		event.target.pauseVideo();
	}

	render() {
		const headline = 'Bestie'
		const sub_headline = 'Your Robot Best Friend'
		const main_pic = { mainpic }
		var preface = 'Bestie is a social robot that can read your facial expressions and listen to your commands by implementing machine learning algorithms.'
		const role = 'Role: Design, Development, Video Editing.'
		const time = 'Date: Noc 2020 - Dec 2020 (3 weeks)';
		const client = 'Type: Student Project';
		const built = 'Built With : Tensorflow.js, Google NLP API, Speech Recognition, OpenWeather API.';
		const award = ['Lauch Site (Web Only)'];
		const link = 'https://dark-passage-297513.web.app/';
		const opts = {
			//height: '480px',
			//width: '80%',
			playerVars: {
				// https://youtu.be/9K87NVDHdzs
				autoplay: 1,
			},
		};
		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [
			{
				title: 'Background',
				desc: "Bestie was an individual project that I designed and developed at University of Washington in two weeks. The goal of this project was to utilize emerging technologies to build up simple applications and demo it through videos. By implementing image recognition functionalities, Bestie is capable of detecting emotions with high accuracy. And by utilizing voice recognition technologies, Bestie can understand your language and be able to perform tasks under your commands."

			},

			{
				title: 'Image Recognition',
				desc: 'To allow emotion detection functionalities, I implemented \bface-api.js\b from TensorFlow javascript library. The machine learning models can recognize the facial expressions such as happy, sad, surprised, and neutral. The models also return canvas elements so we render facial landmarks and accuracy. ',
				pic: [{ w1pic_2 }]
			},

			{
				title: 'Speech Recognition & Sentiment Analysis',
				desc: 'To enable the hearing and speaking ability for Bestie, I utilized \bspeech recognition\b which can detect and recognize users speech and convert it into texts, and then I used speak function to make the robot "replies” in machine voice. Besides, sentiment analysis is also included using \bGoogle NPL API\b, so we can quantify sentiment based on text data. With the help of speech recognition functionality, Bestie can help users to perform simple tasks such as report the weather and spin the wheel.',
				pic: [{ w1pic_3 }]
			},
			{
				title: 'Video Editing',
				desc: 'To “sell” the prototype, I made a video to demonstrate  the capabilities of Bestie. I used Adobe Premiere as editor and the main background music was Suzi Wu’s Eat Them Apple.',
				pic: [{ w1pic_4 }]
			},

			{
				title: 'Takeaways',
				desc: 'The biggest takeaway is that I realize the importance of having the development experiences for designers. After solo designing and developing Bestie, I have gained knowledge of creating products in a way that I can\'t ever imagine as a designer. I learned what machine learning is capable of by implementing the tensorflow library, and I knew how to get user input data by storing value to Firebase. If I hadn\'t challenged myself to develop Bestie, I wouldn’t have understood the possibilities and limitations of designing products.',

			},

		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='video'>
						<YouTube videoId="9K87NVDHdzs" opts={opts} onReady={this._onReady} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='client'>
						<span>💼 {client}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='built'>
						🛠 {built}
					</div>
					<div className='award'>
						<a href={link} target="_blank"> &#10153; Lauch Site (Web Only)</a>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool1;
