import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w9pic_1.png';
import w1pic_2 from './img/w9pic_2.png';
import w1pic_3 from './img/w9pic_3.png';
import w1pic_4 from './img/w9pic_4.png';
import w1pic_5 from './img/w8pic_5.png';
import w1pic_6 from './img/w1pic_6.jpg';
import w1pic_7 from './img/w1pic_7.jpg';

class PortfolioItemPool1 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	render() {
		const headline = 'ML Mobile'
		const sub_headline = 'Motherboard Defect Detection Tool'
		const main_pic = { mainpic }
		var preface = '\bML Mobile\b is a mobile app for technical staff to detect motherboard defections automatically using supervised machine learning.'
		const role = 'Role: User Research, Interaction Design, Visual Design.'
		const time = 'Date: Nov 2019 ';
		const client = 'Client: Google';
		const nda = 'Due to NDA, I’m not allowed to reveal complete design processes and deliverables. All the data is redacted and would be used for portfolio building only.'
		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [
			{
				title: 'Overview',
				desc: "\bML mobile\b is an app for \btechnical staff\b to \bdetect motherboard defections more quickly\b when examining the failure components. We came up with this idea during our field trips to the local factory. We observed that technical staff spend a lot of time identifying defections on PCB and yet still make mistakes. By applying supervised machine learning modals, the app can accelerate the examination process and increase the accuracy."

			},

			{
				title: 'Identifying Opportunities',
				desc: 'As a product designer, I’m always \bseeking inspiration\b and \bopen to new ideas\b. During one of the field trips to the local factory, we noticed that the technician wasted too much time on fixing the motherboards. We quickly identified this could be the opportunity to increase efficiency for the local manufacture. Possible solutions were proposed after interviewing with stakeholders.',
				pic: [{ w1pic_2 }]
			},

			{
				title: 'Rapid Prototyping',
				subtitle:'Wireframe',
				desc: 'Wireframe and prototpye were completed after quick brainstorming with team members.',
				pic: [{ w1pic_3 }]
			},

			{
				subtitle:'Prototype',
				pic: [{ w1pic_4 }]
			},

			{
				title: 'Takeaways',
				subtitle: 'Identifying opportunities and testing in the early stage',
				desc: 'To validate the idea, we reached out to potential users in the very early stage. Low fidelity prototype was used to conduct the testing and constructive feedback was collected. What’s more, we even gained more resources from the users after testing because they really loved the app we proposed.',

			},

		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='pic'>
						<img src={mainpic} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='client'>
						<span>💼 {client}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='nda'>
						<span>{nda}</span>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool1;
