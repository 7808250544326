import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w7pic_1.png';
import w1pic_2 from './img/w7pic_2.png';
import w1pic_3 from './img/w7pic_3.png';
import w1pic_4 from './img/w7pic_4.png';
import w1pic_5 from './img/w7pic_5.png';
import w1pic_6 from './img/w7pic_6.png';
import w1pic_7 from './img/w1pic_7.jpg';

class PortfolioItemPool1 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	render() {
		const headline = 'Nest Analytics'
		const sub_headline = 'Data Analytics Dashboard'
		const main_pic = { mainpic }
		var preface = '\bNest Analytics\b is a platform for engineers who want to save time and analyze data more effectively during hardware product development cycles.'
		var role = '\bRole\b : User Research, Interaction Design, Visual Design, Product Icon Design, Illustration, Usability Testing.'
		var time = '\bDate\b : Nov 2019 ';
		var client = '\bClient\b : Google';
		const nda = 'Due to NDA, I’m not allowed to reveal complete design processes and deliverables. All the data is redacted and would be used for portfolio building only.'
		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [
			{
				title: 'Overview',
				desc: "\bNest Analytics\b is a software application I designed for \bengineers\b to \banalyze data and identify critical issues quickly\b when manufacturing hardware products. Applying algorithms and integrating with cloud computing services, the dashboard enables the entire hardware engineering team to \bmake critical decisions rapidly\b by offering \breal time\b and \bappropriate data.\b",
			},

			{
				title: 'User Problem',
				desc: 'Many technical product managers and engineers needed to analyze manufacturing data to identify issues remotely when they were away from the factory. However, a large number of the \bdata were either unintegrated or not visualized\b. It was \bdifficult for users to make decisions in a short time\b. What they need is a tool that could help organize information effectively and correctly.',

			},

			{
				title: 'Design Challenges',
				desc: '🚩 Hardware product development \bprocesses were complex\b and the data were scattered across different platforms.\n 🚩 \bUser journeys were conflicted\b as users were from more than 5 different positions. \n 🚩 Misinterpreting the data could lead to \bgreat loss\b of the production line \bin seconds\b.\n 🚩 As the only designer in the team, I had to \blead all the design related tasks\b. ',

			},

			{
				title: 'Mapping out Critical User Journey',
				desc: 'Field study was conducted to explore critical user journeys and to discover pain points. I joined one of the manufacturing builds in China with local teams and interviewed more than 25 users from 5 different positions in two weeks. We \bprioritized user journeys\b and \buncovered pain points\b. Below is an examples of critical user journey we discovered:',
				pic: [{ w1pic_2 }]

			},

			{
				title: 'Creating Minimum Viable Product',
				desc: 'Since the scale of hardware product development is large and could take months to run through all the processes, we planned to launch the Analytic Dashboard in multiple stages and started building features to attract early-adopters and \bvalidate product ideas in early stages\b. According to user research results, the biggest pain point was that users spend a lot of time organizing data manually. Therefore the first thing we did was to help users find an efficient way to do analytics.',
			},

			{
				title: 'From Data to Information',
				subtitle: 'Understanding data type and prioritized information',
				desc: 'To accelerate the decision making processes, data was prioritized and the important metrics were calculated in advance to better help users identify critical issues in time.',
				pic: [{ w1pic_3 }]
			},

			{
				subtitle: 'Creating Infographics',
				desc: 'Infographics were applied to help users understand complex data easily. We were not just organizing data, but visualizing it by applying appropriate formats including charts, tables and diagrams to different types of data.',
				pic: [{ w1pic_4 }]
			},

			{
				title: 'Dashboard Design',
				subtitle: 'Product icon concepts',
				desc:'Product icons communicate the core idea and intent of a product. Since the Analytic Dashboard is about analyzing big data and identifying trends, we designed the icon to be the combination of data and analysis.',
				pic: [{ w1pic_5 }]
			},

			{
				subtitle: 'Managing information',
				desc:'By reorganizing and filtering data, the Dashboard not only helpes users to manage information but provides critical insights.',
				pic: [{ w1pic_6 }]
			},

			{
				title: 'Validating Design',
				desc:'User satisfaction surveys were sent out to validate designs. Besides, Google Analytics tracking code was implemented to measure user behaviors and examine design metrics. All the feedback were trangulated as a reference for the next iteration.',

			},

			{
				title: 'Takeaways',
				subtitle: 'The challenge of managing complexity',
				desc: 'Unlike designing for consumer facing products that have simple user journeys, Analytics Dashboard is an internal facing application that has multiple users in different positions and intertwined user journeys. That is to say, the application we created has to \baccommodate complex requirements\b yet still not complicated to use. My job was to \bmanage the complexity\b and \bdesign an easy way out\b.',

			},

			{
				subtitle: 'The power of Minimum Viable Product',
				desc: 'Due to the time and resource limitations, Analytics Dashboard launched with only few new features implemented. However, the impact of these few features are not small because they are developed based on critical user journeys. By \blaunching the Minimum Viable Product\b as stepping stones, we not only successfully \bgained more users\b, but \bwon more resources for future developments\b.'
			},


		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='pic'>
						<img src={mainpic} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='client'>
						<span>💼 {client}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='nda'>
						<span>{nda}</span>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool1;
