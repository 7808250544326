import React, { Component } from 'react';
import YouTube from 'react-youtube';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w9pic_1.png';
import w1pic_2 from './img/w10pic_1.png';
import w1pic_3 from './img/w10pic_2.png';
import w1pic_4 from './img/w10pic_3.gif';
import w1pic_5 from './img/w10pic_4.gif';
import w1pic_6 from './img/w10pic_5.png';
import w1pic_7 from './img/w10pic_3.png';

class PortfolioItemPool1 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	_onReady(event) {
		// access to player in all event handlers via event.target
		event.target.pauseVideo();
	}

	render() {
		const headline = 'H Cam'
		const sub_headline = 'Hygiene Detection Camera'
		const main_pic = { mainpic }
		var preface = '\bH Cam\b is a smart camera that aims to prevent the spread of COVID-19 by identifying areas that are touched by people and detecting whether it is wiped properly.'
		const role = 'Role : IoT Design, 3D Modeling, Front-end web development.'
		const time = 'Date : Jan 2021 - Mar 2021 (10 weeks)';
		const client = 'Type : Student Project';
		const built = 'Built With : Raspberry pi 4, NOIR Camera, FLIR Lepton 2, Microsoft Azure (Storage, Cognitive Service Computer Vision), OpenCV, Firebase.';
		const award = ['Petra 2021 paper published', 'Finalist of Alaska Airlines Innovation Challenge 2021'];
		const petra = 'https://dl.acm.org/doi/10.1145/3453892.3464896';
		const alaska = 'https://blog.foster.uw.edu/finalists-2021-environmental-innovation-challenge/';
		const nda = 'Due to NDA, I’m not allowed to reveal complete design processes and deliverables.'
		const opts = {
			//height: '480px',
			//width: '80%',
			playerVars: {
				// https://www.youtube.com/watch?v=9OYWfT9oZJU
				autoplay: 1,
			},
		};
		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [
			{
				title: 'Background',
				desc: "H Cam was the project that two members and I developed at University of Washington in 10 weeks. We came up with this idea because all of us can’t avoid using a shared space during the pandemic. We designed H Cam to \breduce the anxiety\b for \bpeople who need to use shared spaces\b during the \bCOVID-19 pandemic.\b"

			},

			{
				title: 'Problem',
				desc: 'According to the CDC, even though COVID-19 spreads mainly through close contact from person to person, \bthere’s still the possibility\b that \bthe virus\b will \btransfer through surfaces due to hand contact\b. For people who have higher potential for exposure to the public at their working environments, for example students, Lyft drivers, Airbnb Owners, restaurant waiters, and especially janitors, it is difficult for them to identify exactly which areas have been touched by people and if the area is being wiped out properly.',

			},

			{
				title: 'The Solution',
				desc: 'Equipped with a thermal imaging camera, H Cam is capable of detecting the touched and wiped areas through \btemperature change of the surface\b. Implemented with object detection technology, H Cam recognizes the target surface that are \btouched or wiped by humans\b. Moreover, we developed H Cam App to allow users to access real time information remotely and timely. By simply \bscanning the QR code on H Cam\b, users can \baccess the live cleaning information \bof a specific share space, such as the percentage of the touched and wiped areas, and also the cleaning history.',
				subtitle: 'Overall Architecture',
				pic: [{ w1pic_2 }]
			},

			{
				title: 'Hardware & Package Design',
				subtitle: 'Drawing',
				pic: [{ w1pic_3 }]
			},

			{
				subtitle: '3D Printed Protopye',
				pic: [{w1pic_7}]
			},

			{
				title: 'Behavior Detection',
				subtitle: 'Touched Detection',
				pic: [{ w1pic_4 }]
			},

			{
				subtitle: 'Wiped Detection',
				pic: [{ w1pic_5 }]
			},

			{
				title: 'H Cam App',
				subtitle: 'Real time cleaning information of the shared space',
				pic: [{ w1pic_6 }]
			},

			{
				title: 'Takeaways',
				desc: 'What I’ve learned most from developing H Cam is how to design IoT products : Starting from choosing appropriate sensors to collect signals, 3D modeling prototypes, storing data to the cloud, all along the way to training machine learning models for object detections. What’s more, I learned how to pitch to potential investors while competing for the Alaska Airlines Innovation Challenge 2021.',

			},

		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='video'>
						<YouTube videoId="9OYWfT9oZJU" opts={opts} onReady={this._onReady} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='client'>
						<span>💼 {client}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='built'>
						🛠 {built}
					</div>
					<div className='award'>
						<a href={petra} target="_blank">  &#10153; Award: {award[0]}</a>
					</div>
					<div className='award'>
						<a href={alaska} target="_blank"> &#10153; Award: {award[1]}</a>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool1;
