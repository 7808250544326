import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w6pic_1.png';
import w6pic_2 from './img/w6pic_2.gif';
import w6pic_3 from './img/w6pic_3.gif';
import w6pic_4 from './img/w6pic_4.gif';
import w6pic_5 from './img/w6pic_5.gif';



class PortfolioItemPool6 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	render() {
		const headline = 'BRWL LLC (Baby Robot Wonderland)'
		const sub_headline = 'Stories with twisted sense of humor'
		const role = 'Role: Founder, Illustrator, Animator'
		const time = 'Date: April 2019 - Present';
		const award = ['Official Instagrm', 'Online Store'];
		const link_ig = 'https://www.instagram.com/babyrobotwl/';
		const link_rb = 'https://www.redbubble.com/people/babyrobotwl/shop?asc=u';
		const main_pic = { mainpic }
		var preface = 'Funny stories of baby robots and unknown creatures in WonderLand.'



		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [

			{
				title: 'Overview',
				desc: 'I started to create humorous animations because some of my close friends suffered from mental illness unexpectedly. My aspiration is to \bbring a moment of happiness\b to people who feel frustrated and depressed. I hope the funny stories of characters in my illustrations can \bcheer people up\b and motivate them to move on. \bWith 50 posts\b, Baby Robot Wonderland has \bgained 2400+ followers\b and \b14,000+ likes\b on instagram.',

			},
			{
				title: 'Character Design',
				pic: [{ w6pic_2 }, { w6pic_3 }, { w6pic_4 }, { w6pic_5 }]
			},



		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='pic'>
						<img src={mainpic} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='award'>
						<span> <a href={link_ig} target="_blank">&#10153; {award[0]}</a></span>
					</div>
					<div className='award'>
						<span> <a href={link_rb} target="_blank">&#10153; {award[1]}</a></span>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool6;
