import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/w12pic_1.png';
import w1pic_2 from './img/w12pic_2.png';
import w1pic_3 from './img/w12pic_3.png';
import w1pic_4 from './img/w12pic_4.png';
import w1pic_5 from './img/w12pic_5.png';
import w1pic_6 from './img/w12pic_6.png';
import w1pic_7 from './img/w12pic_7.png';
import w1pic_8 from './img/w12pic_8.png';
import w1pic_9 from './img/w12pic_9.png';
import w1pic_10 from './img/w12pic_10.png';
import w1pic_11 from './img/w12pic_11.png';
import w1pic_12 from './img/w12pic_12.png';

class PortfolioItemPool1 extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	render() {
		const headline = 'Equi-Track'
		const sub_headline = 'Horse Tracking and Health Monitoring Application'
		const main_pic = { mainpic }
		var preface = '\bEqui-Track\b is a wearable piece of horse tack for private horse owners and horse farm managers to track location and activity of their horses.'
		const role = 'Role: IoT Deign, Front-end Development.'
		const time = 'Date: Jun 2021 - Dec 2021 ';
		const client = 'Client: T-Mobile';
		const nda = 'This project is still in progress and new content will be updated soon!'
		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [
			{
				title: 'Overview',
				desc: "Equi-Track is a project collaboration with T-mobile. The Goal of this project was to track the GPS location and detect activities of the horses when an emergency happens by \butilizing 5G technology.\b"

			},

			{
				title: 'Problem',
				desc: 'There are an estimated 7.2 million horses in the U.S and the average horse costs $3,000 - $5,000 and incurs many other expenses such as insurance, feed, stabling fees, and more.Not every horse goes missing because of crime; More and more \bhorses leave their land\b due to \bemergency\b, such as \bnatural disasters\b or \bthreats by surroundings\b. There are a lot of tracking devices on the market for riding purposes, but \bnone of them can be used under emergency situations.\b',
			},

			{
				title: 'Primary Research',
				pic: [{ w1pic_2 }]
			},

			{
				subtitle:'Persona',
				pic: [{ w1pic_3 },{ w1pic_4 }]
			},

			{
				title: 'Mobile App Desig',
				pic: [{ w1pic_12 },{ w1pic_5 }]
				
			},

			{
				title: 'Watch Design',
				subtitle:'Wireframe',
				pic: [{ w1pic_6 }]				
			},

			{
				subtitle:'Watch Faces',
				pic: [{ w1pic_7 }]
			},

			{
				title: 'Hardware & Package',
				subtitle:'HW Components',
				pic: [{ w1pic_9 }]				
			},

			{
				subtitle:'Package Positions',
				pic: [{ w1pic_8 }]
			},

			{
				title: 'Initial Prototype Testing',
				pic: [{ w1pic_10 },{ w1pic_11 }]				
			},

		]


		preface = Characterize(preface);

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'url') {
					return (
						<div className="external-link">
							<Link to={content.url} target='_blank'>
								Launch Site
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<div>
				<div className='portfolio-description'>
					<div className='headline'>
						<span>{headline}</span>
					</div>
					<div className='sub-headline'>
						<span>{sub_headline}</span>
					</div>
					<div className='pic'>
						<img src={mainpic} />
					</div>
					<div className='preface'>
						<span>{preface}</span>
					</div>
					<div className='time'>
						<span>🗓 {time}</span>
					</div>
					<div className='client'>
						<span>💼 {client}</span>
					</div>
					<div className='role'>
						<span>👩‍💻 {role}</span>
					</div>
					<div className='nda'>
						<span>{nda}</span>
					</div>
					<div className='content'>
						{display}
					</div>
				</div>
				<div className='previous-next'>
					<Navigation index={this.props.index} category={this.props.category} c_key={this.props.c_key} />
				</div>
			</div>
		)
	}
}

export default PortfolioItemPool1;
