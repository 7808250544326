import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './PortfolioItemPool.css';
import Header from './Header';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Link
} from 'react-router-dom';
import Navigation from './Navigation';
import Characterize from './characterize';

import mainpic from './img/hua_profile.jpg';
import pdf from './pdf/cv.pdf'



class About extends Component {

	componentDidMount() {
		//default of scollrestoration is auto
		window.history.scollRestoration = 'manual'
		document.body.scrollTop = 0
		window.scrollTo(0, 0);
	}

	render() {
		const headline = 'A designer-developer hybrid with 5+ years product design experience.'
		const sub_headline = ['Currently a freelancer, previously designing @Google @T-Mobile @iTutorGroup. Recent MSTI Graduate @University of Washington.']
		const tag = [' Oct 2017 - Oct 2019 | Taipei, Taiwan','Oct 2014 - Feb 2017 | Taipei, Taiwan','Jun 2021 - Present | Bellevue, the U.S.', 'Mar 2019 - Present | Worldwide', 'Mar 2021 - Present | Bellevue, the U.S.', 'Sep 2020 - Present | Seattle, the U.S', 'Sep 2013 - July 2014 | Beijing, China', 'Sep 2007 - Jun 2011 | Taipei, Taiwan','User Research, Interaction Design, Prototyping, Visual Design, Usability Testing','HTML, CSS, JavaScript, React.js']
		const main_pic = { mainpic }
		var preface = 'I studied finance & engineering in Taiwan and Beijing, and gained \bdesign & development\b knowledge from working in Taipei and Seattle. During my career, I have designed consumer facing applications as well as internal facing systems in different types of industries, including IT, manufacturing, and online learning.\n\nAs a product designer, I enjoy \btackling complex problems\b and \bcreating simple solutions\b. As an enthusiastic maker, I have  strong passions in creating artworks such as illustrations, animations, and 3D printing. What’s more, I love sports especially playing badminton with friends and swimming in summer.'



		/********
		 contents has the following keys:
		 1. title
		 2. desc
		 3. subtitle
		 4. subdesc 
		 5. pic
		 ********/
		var contents = [

			{
				/*subtitle: 'RESUME',*/
				Resume: 'pdf/cv.pdf',
				ADPList: 'www.adplist.org/mentors/hua-lo',
				LinkedIn: 'www.linkedin.com/in/hualo/'
				
			}/*,
			
			{
				subtitle: 'SOCIAL NETWORK',			 
                Instagram: 'www.instagram.com/walo.lo/'
			},*/


		]


		preface = Characterize(preface);

		const check = (content) => {
			if ('url' in content) {
				return (
					<Link to={content.url} target='_blank'>
						Launch Site
					</Link>
				)
			}
		}

		const display = contents.map((content, index) => {
			return Object.keys(content).map(function (key) {

				if (key === 'pic') {
					var pic;
					pic = content.pic.map(p => {
						return <div className='pic'><img src={p[Object.keys(p)[0]]} /></div>
					})

					return pic
				}
				else if (key === 'ADPList' || key === 'LinkedIn') {
					return (
						<div className="external-link">
							<a className="external-link-text" href={'https://' + content[key]} target='_blank'>
								&#10153; {key}
							</a>
						</div>
					)
				}
				else if (key === 'Resume') {
					return (
						<div className="external-link">
							<Link className="external-link-text" to={pdf} target='_blank'>
								&#10153; {key}
							</Link>
						</div>
					)
				}
				else {
					return (
						<div className={key}>
							{Characterize(content[key])}
						</div>

					)
				}
			})
		})

		return (
			<Router>
				<div>
					<div className='portfolio-description'>
						<div className='about_content'>
							<div className='about_pic'>
								<img src={mainpic} />
							</div>
							<div>
								<div className='headline'>
								
								<div className='headline_2'> {headline}</div>
								<div className='headline_3'> {sub_headline}</div>
							</div>
								<div className='about'>
									<span>{preface}</span>
								</div>
								<div className='content'>
									{display}
								</div>
							</div>
						</div>
						
						<div className='bottom_margin'></div>
					</div>
				</div>
			</Router>
		)
	}
}

export default About;


						/*<div>
							<div className='headline_2'> EXPERIENCE</div>
							<div className='headline_3'> {sub_headline[2]}</div>
							<div className='headline_4'> {tag[2]}</div>
							<div className='headline_3'> {sub_headline[0]}</div>
							<div className='headline_4'> {tag[0]}</div>
							<div className='headline_3'> {sub_headline[1]}</div>
							<div className='headline_4'> {tag[1]}</div>
						</div>
						<div>
							<div className='headline_2'> OTHER EXPERIENCE</div>
							<div className='headline_3'> {sub_headline[3]}</div>
							<div className='headline_4'> {tag[3]}</div>
							<div className='headline_3'> {sub_headline[4]}</div>
							<div className='headline_4'> {tag[4]}</div>
						</div>
						<div>
							<div className='headline_2'> EDUCATION</div>
							<div className='headline_3'> {sub_headline[5]}</div>
							<div className='headline_4'> {tag[5]}</div>
							<div className='headline_3'> {sub_headline[6]}</div>
							<div className='headline_4'> {tag[6]}</div>
							<div className='headline_3'> {sub_headline[7]}</div>
							<div className='headline_4'> {tag[7]}</div>
						</div>
						<div>
							<div className='headline_2'> SKILLS</div>
							<div className='headline_3'> {sub_headline[8]}</div>
							<div className='headline_4'> {tag[8]}</div>
							<div className='headline_3'> {sub_headline[9]}</div>
							<div className='headline_4'> {tag[9]}</div>
						</div>*/
						/*<div style={{ color: "#0077FF" }}> Hi! 👋 I'm Hua.</div>
						*/